import { FormattedMessage } from 'react-intl';
import { Switch, twMerge, Size } from '@pledge-earth/product-language';
import { useLocation } from 'react-router-dom';
import { useCallback } from 'react';

import { useIsTestMode } from '../../../hooks/useIsTestMode';
import { useNavigateWithTestMode } from '../../../hooks/useNavigateWithTestMode';

export function TestModeSwitch() {
  const isTestMode = useIsTestMode();
  const { pathname } = useLocation();
  const navigateWithTestMode = useNavigateWithTestMode();

  const switchMode = useCallback(() => {
    navigateWithTestMode(pathname, { testMode: !isTestMode });
  }, [isTestMode, navigateWithTestMode, pathname]);

  return (
    <div
      className={twMerge('flex h-8 items-center rounded-sm px-3 py-1.5', isTestMode && 'bg-warning-subdued')}
      data-cy="test-mode-switch-container"
    >
      <Switch
        variant="warning"
        isSelected={isTestMode}
        size={Size.Compact}
        onChange={switchMode}
        data-cy="test-mode-switch-switch"
        className="-mx-1"
      >
        <span
          className={twMerge(
            'inline-block font-medium whitespace-nowrap opacity-100 transition-opacity duration-200 ease-out lg:-mt-[0.313rem] lg:opacity-100',
            'group-data-[menu-collapsed]/layout:pointer-events-none lg:group-data-[menu-collapsed]/layout:opacity-0',
          )}
        >
          <FormattedMessage id="leftMenu.mode" />
        </span>
      </Switch>
    </div>
  );
}
